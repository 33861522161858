.review{
  border: solid 1px #fa97ff;
  padding: 20px;

}
html{
  background-color: black;
}
.webdevelopment
{
  background-color: black;
}
#scrollDist{
  background-color: rgb(48,48,48);
}
p.card-text {
  color:black;
}
p.card-text {
  color:black;
}

table {
  color: #f700ff;
}

th {
  color: white;
  font-size: larger;
  font-weight: 400;
}
td {
  color: white;
  font-weight: bolder;
  font-size: larger;
  text-align: center;
}

td.plus {
  color: lightgreen;
}

tr {
  color: white;
  text-align: center;
}

.ldiv {
  position:absolute;

}

#conta {
  position:fixed;
  width:100%;
  height:100%;
  overflow:hidden;
}

#scrollDist {
  width:100%;
  height:400%;
}

body{
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgb(48, 48, 48);
}

.intro{
  background-image: linear-gradient(to bottom, #000000, rgb(48, 48, 48))
}

body {
    font-family: 'Fredoka', sans-serif;
}

h1, h2, h3 {
  color: grey;
}


p.ph {
    color: white;
    font-family: 'Quicksand', sans-serif;
}


h1.main_letters{
  font-size: 10vw;
  color: transparent;
  line-height: 1;
  display: flex;
  padding-bottom: 0.4vw;
  padding-top: 0.1vw;
  overflow: hidden;
  text-shadow:  0 0 20px rgb(148, 42, 148), 0 0 10px rgb(247, 144, 247);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fcadff;
  font-weight: bold;
}


h1.main_letters div{
  display: inline-block;
}

h1.main_letters div::before{
  position: absolute;
  top: 0;
  left: 0;
  content:attr(data-char);
  transform: translateY(-103%);
}


h1.other_letters{
  padding: 0%;
  font-size: 7vw;
  color: transparent;
  line-height: 1;
  display: flex;
  padding-bottom: 0.6vw;
  padding-top: 0.1vw;
  padding-left: 1vw;
  overflow:hidden;
  text-shadow:  0 0 20px rgb(18, 116, 148), 0 0 10px #00ffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #00ffff;
  font-weight: lighter;
  -webkit-animation: buzz 0.01s infinite alternate;
          animation: buzz 0.01s infinite alternate;
  -webkit-transform: translateZ(0) translate3D(0, 0, 0);
          transform: translateZ(0) translate3D(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: opacity;
}

@-webkit-keyframes buzz {
  70% {
    opacity: 0.80;
  }
}

@keyframes buzz {
  70% {
    opacity: 0.80;
  }
}
@-webkit-keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}
@keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}

.about {
    width: 100%;
    background-color: rgb(48, 48, 48);
}





.homeButton a {
  height: 50px;
  width: 50px;
  background: #000;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  margin: 2px
}


.homeButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000
}


.contact{
    width: 100%;
    background-color: black;
}


.contactUsBlueH1 {
  font-family: 'Fredoka', sans-serif;
  font-weight: lighter;
  font-size: 5vw;
  color: transparent;
  text-shadow: 0 0 30px #29dbfe;
  -webkit-animation: buzz 0.01s infinite alternate;
          animation: buzz 0.01s infinite alternate;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #d4eaff;
}



.contactUs {
  color: transparent;
  outline-style: groove;
  outline-color: #fa97ff;
  text-shadow: 0 0 10px #f700ff;
  -webkit-animation: buzz 0.01s infinite alternate;
          animation: buzz 0.01s infinite alternate;
  -webkit-text-stroke-color: #fa97ff;
  -webkit-text-stroke-width: 1px;
}

.contactUsBlue{
  color: transparent;
  outline-style: groove;
  outline-color: #29dbfe;
  text-shadow: 0 0 10px #166f81;
  -webkit-animation: buzz 0.01s infinite alternate;
          animation: buzz 0.01s infinite alternate;
  -webkit-text-stroke-color: #97fdff;
  -webkit-text-stroke-width: 1px;
}

a.noOutline {
  outline: none;
}

h3#email {
  outline: none;
}

.philosophy{
  font-size: 6vw;
}

.shimmer {
  color: rgba(49, 24, 0, 0.445);
  background: -webkit-gradient(linear, left top, right top, from(rgb(49, 24, 0)), to(rgb(49, 24, 0)), color-stop(0.5, rgb(216, 158, 0)));
  background: -moz-gradient(linear, left top, right top, from(rgb(49, 24, 0)), to(rgb(49, 24, 0)), color-stop(0.5, rgb(216, 158, 0)));
  background: gradient(linear, left top, right top, from(rgb(49, 24, 0)), to(rgb(49, 24, 0)), color-stop(0.5, rgb(255, 187, 0)));
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
  -webkit-animation-direction: alternate;
  -webkit-text-stroke-color: rgb(255, 166, 0);
  -webkit-text-stroke-width:  0.5px;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

#cyber {
  animation-delay: 1s;
}

#renaissance {
  animation-delay: 2s;
}

.card-title {
  color: white;
}

.card.our-team {
  background-color: transparent;
  outline-style: groove;
  outline-color: #fa97ff;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1px;
  
}

h1#our_services {
  font-size: 7vw;
  background-repeat: repeat;

  
}




.aboutext{
  background-image: linear-gradient(to top, #000000, rgb(48, 48, 48))
}



.portfolioBtn {
  background-color: transparent;
}

@media (max-width:799px) {
  h1#our_services{
    font-size: 13vw;
  }

  h1.main_letters{
    text-shadow:  0 0 5px rgb(230, 50, 230), 0 0 8px rgb(194, 112, 194);
  }

  h1.other_letters{
    text-shadow:  0 0 3px rgb(47, 148, 182), 0 0 10px rgb(21, 114, 145);
  }

  h1.contactUs{
    font-size: 14vw;
  }

  #email {
    display: none;
  }

  .desktopOnly {
    display: none;
  }

  .philosophy{
    font-size: 12vw;
  }

  #v_b{
    display: none;
  }

  #check {
    text-align: center;
  }

  .clientPhoto {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
  }

  a#contactBtn{
    font-size: larger;
    font-weight: 100;
  }

  #pup {
    display: none;
  }

  .contactUsBlueH1{
    font-size: 14vw;
  }
}

@media (min-width: 800px) {
  #contactsMob {
    display: none;
  }

  .pageCont {
    padding-bottom: 150px;
  }

  .clientPhoto {
    width: 250px;
    height: 250px;
  }

}


.portfolio {
  background-color: #000;
}


.carousel-caption {
  color: rgb(255, 255, 255);
  opacity: 0.5;
  font-size: 4vw;
  background-color: black;
  border: white solid 1px;
  padding-bottom: 0rem;
  padding-top: 0rem;
}

.card-img-top {
  height: 350px;
}

p#check {
  background-color: transparent;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1px;
}

.btn:hover {
  box-shadow: 0 0 40px white;
}



.clientPhoto:hover {
  box-shadow: 10px 10px 100px white;
}
.websiteViewer{
  border: solid 1px #fa97ff;
}
.websiteViewer:hover {
  border: solid 1px #fa97ff;
  box-shadow: 3px 3px 45px pink;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.caseBtn:focus {
  box-shadow: none;
  border: solid 1px #fa97ff;
}

td.price {
  font-size:medium;
}